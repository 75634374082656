<template>
    <div class="col-md-12 technologiesStyle my-2" style="padding-top: 5% !important;">
        <template v-if="isEnabled">
            <template v-if="tests.length > 0">
                <div class="row" style="margin-top: 1%;">
                    <div class="col-sm-2">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="testsList"></b-pagination>
                    </div>
                    <div class="col-sm-7">
                        <b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
                            :options="pageOptions"></b-form-select>
                        <div class="numPerPage paginationSelectorTable mx-3 my-2">Level of difficulty: </div>
                        <b-form-select class="numPerPage paginationSelectorTable" v-model="difficultyModel"
                            @change="filterTests" :options="levels"> </b-form-select>
                        <button class="btn btn-primary table-selection-button" type="button"
                            @click="addAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all
                            rows</button>
                        <button class="btn btn-primary table-selection-button" type="button"
                            @click="dismissAllRows"><font-awesome-icon icon="ban" class="white-color" /> Clear
                            All</button>
                    </div>
                    <div class="col-sm-3">
                        <b-form-input id="filter-input" v-model="filter" type="search"
                            :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                    </div>
                </div>

                <b-table v-if="show" id="testsList" :per-page="perPage" :current-page="currentPage" :fields="headers"
                    :items="filterTests()" :filter="filter" select-mode="multi" responsive="sm" ref="testsTable"
                    :tbody-tr-class="rowClass">
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not Selected</span>
                        </template>
                    </template>
                    <template #cell(title)="data">
                        {{ data.item.title }}
                        <ul class="ti-tags small ps-0">
                            <li class="ti-tag ti-valid" v-for="item in data.item.tags" v-bind:key="item.id">
                                <div class="ti-content">
                                    <div class="ti-tag-center">
                                        <span>{{ item.name }}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </template>
                    <template #cell(description)="data">
                        <div v-if="data.item.description != null && data.item.description != ''">
                            {{ truncatedDescription(data.item.description) }}
                        </div>
                        <div v-else>
                            No description provided
                        </div>
                    </template>
                    <template #cell(done)="data">
                        {{ data.item.done }}
                    </template>
                    <template #cell(average_qualification)="data">
                        <div v-if="data.item.average_qualification != null || data.item.average_qualification == ''">
                            {{ data.item.average_qualification.toFixed(2) }}
                        </div>
                        <div v-else>
                            Not available
                        </div>
                    </template>
                    <template #cell(difficulty_level)="data">
                        <p v-if="data.item.numberQuestions != 0" class="difficultyLevelTag"
                            :style="'background-color: ' + colorDiff[data.item.difficulty_level]">
                            {{ data.item.difficulty_level.toUpperCase() }}
                        </p>
                        <p v-else class="difficultyLevelTag"
                            :style="'background-color: ' + colorDiff[data.item.difficulty_level]">
                            {{ data.item.difficulty_level.toUpperCase() }}
                        </p>
                    </template>
                    <template #cell(options)="data">
                        <button v-b-tooltip.hover title="Add" v-if="!data.item.show" class="btn btn-secondary"
                            v-bind:id="'validate_' + data.index"
                            @click="addTest(data.item, data.index)"><font-awesome-icon icon="plus" /></button>
                        <button v-b-tooltip.hover title="Dismiss" v-else class="btn btn-secondary"
                            v-bind:id="'avoid_' + data.index"
                            @click="dismissTest(data.item, data.index)"><font-awesome-icon icon="ban" /></button>
                        <button v-b-tooltip.hover title="Show Test" class="btn btn-secondary"
                            @click="onTestSelected(data.item.id)">
                            <font-awesome-icon icon="eye" />
                        </button>
                    </template>
                </b-table>
                <div id="message" v-if="message != ''" class="alert"
                    :class="successfull ? 'alert-success' : 'alert-danger'">
                    {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
                            icon="times" />
                    </b-button>
                </div>
            </template>
            <template v-else>
                <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results
                    found.
                </h3>
            </template>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-sm-12">
                    <h5 style="text-align: center !important;">Module disabled.</h5>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TestDataService from "@/services/TestDataService";
import BusinessProcessService from "@/services/BusinessProcessService";
import $ from 'jquery'

export default {
    name: "AddTests",
    data() {
        return {
            successfull: false,
            message: "",
            perPage: 10,
            pageOptions: this.$paginationOptions,
            currentPage: 1,
            clickable: true,
            filter: null,
            difficultyModel: "All",
            selectedItems: [],
            selectedTest: null,
            selectedTestIndex: null,
            levels: ["All", "Low", "Medium", "High", "Very High"],
            headers: [
                { key: 'title', label: 'Title', sortable: true, tdClass: 'tdBig' },
                { key: 'description', label: 'Description', tdClass: 'tdBig' },
                { key: 'done', label: 'Attempts', sortable: true, tdClass: 'tdMedium' },
                { key: 'average_qualification', label: 'Average Score (Max. score: 100)', sortable: true, tdClass: 'tdBig' },
                { key: 'difficulty_level', label: 'Level of difficulty', sortable: true, tdClass: 'tdMedium' },
                { key: 'options', label: 'Options', tdClass: 'tdMedium' }
            ]
        }
    },
    props: {
        isEnabled: Boolean,
        tests: Array,
        testsLength: Number,
        show: Boolean,
        colorDiff: Object
    },
    mounted() {
        console.log('this.selectedItems: ', this.selectedItems)
        console.log('this.tests: ', this.tests)

        this.tests.forEach((test, index) => {
            if (test.show) {
                $('.table tr ').eq(index + 1).addClass('validated-question');
                this.selectedItems.push(test);
            }
        })
    },
    watch: {
        selectedItems: {
            handler: function (val) {
                if (val.length != 0) {
                    this.$emit('can-continue', { value: true });
                } else {
                    this.$emit('can-continue', { value: false });
                }
            },
            deep: true
        },
        clickedNext(val) {
            if (val === true) {
                this.$form.$touch();
            }
        }
    },
    computed: {
        rows() {
            return this.testsLength
        }
    },
    methods: {
        onTestSelected(testID) {
            let routeData = this.$router.resolve({ name: 'test-edit-questions', query: { id: testID } });
            window.open(routeData.href, '_blank');
        },
        addTest(item, index) {
            console.log('item: ', item)
            console.log('index: ', index)
            this.$refs["testsTable"].refresh();
            $('.table tr ').eq(index + 1).addClass('validated-question');
            item.show = true;
            this.selectedItems.push(item);
        },
        dismissTest(item, index) {
            this.$refs["testsTable"].refresh();
            item.show = false;
            $('.table tr ').eq(index + 1).removeClass('validated-question');
            this.selectedItems = this.selectedItems.filter((item2) => item2.id !== item.id);
        },
        addAllRows() {
            this.$refs["testsTable"].refresh();
            this.tests.forEach(function (value, index) {
                $('#validate_' + index).css('display', 'none')
                $('#avoid_' + index).css('display', 'block')
                $('.table tr ').eq(index + 1).addClass('validated-question');
            });
            this.selectedItems = this.tests;
        },
        dismissAllRows() {
            this.$refs["testsTable"].refresh();
            this.tests.forEach(function (value, index) {
                $('#validate_' + index).css('display', 'block')
                $('#avoid_' + index).css('display', 'none')
                $('.table tr ').eq(index + 1).removeClass('validated-question');
            });
            this.selectedItems = [];
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return;
            if (item.selected) return 'validated-question';
        },
        filterTests() {
            if (this.difficultyModel == "All") {
                return this.tests;
            } else {
                return this.tests.filter(field => field.difficulty_level == this.difficultyModel);
            }
        },
        close() {
            this.message = "";
        },
        truncatedDescription(description) {
            const maxLength = 128;
            if (description && description.length > maxLength) {
                return description.slice(0, maxLength) + '...';
            }
            return description;
        }
    }
};
</script>