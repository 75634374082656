<template>
	<div class="dashboard">
		<div class="secondNavbar">
			<div class="links-container">
				<router-link v-if="isAdmin" :to="{ path: '/admin' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Home</router-link>
				<router-link v-else-if="isTM" :to="{ path: '/talent_manager' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Home</router-link>
			</div>
			<div class="whiteColor">{{ title }}</div>
		</div>
		<div class="cards cards2 w-75">
			<div class="row full-width justify-content-center">
				<template v-for="card in upperCards">
					<menu-card :cardRouteName="card.routeName" :cardImage="card.image" :cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>

		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
import FooterMini from "@/components/FooterMini.vue";
import MenuCard from '@/components/MenuCard';

export default {
	name: 'SubmenuCompanyManagementAdmin',
	data() {
		return {
			title: "Settings",
			upperCards: []
		}
	},
	components: {
		MenuCard,
		appFooter: FooterMini
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		},
		isTM() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'talent_manager') {
					return true;
				}
			}
			return false
		},
	},
	mounted() {

		if (this.currentUser.company.cv_module_enabled) this.upperCards.push({
			"routeName": "/settings-profiles",
			"image": "images/icons2024/admin_area_settingsprofiles.svg",
			"title": "Profiles",
			"description": "Levels of expertise and Profiles"
		})

		if (this.currentUser.company.hard_skills_module_enabled) this.upperCards.push({
			"routeName": "/settings-exams",
			"image": "images/icons2024/admin_area_settingsexams.svg",
			"title": "Exams",
			"description": "Levels of difficulty, Tags, Questions and Languages"
		})

		if (this.currentUser.company.evaluation_360_module_enabled) this.upperCards.push({
			"routeName": "/settings-bei-360",
			"image": "images/icons2024/admin_area_settingsevaluationbei360.svg",
			"title": "BEI/360 Evaluation Configuration",
			"description": "BEI Configuration and 360 Configuration"
		})

		if (this.currentUser.company.hard_skills_module_enabled) this.upperCards.push({
			"routeName": "/settings-certifications",
			"image": "images/icons2024/admin_area_settingscertifications.svg",
			"title": "Certifications",
			"description": "Certifications"
		})

		/*if (this.currentUser.company.cv_module_enabled) this.upperCards.push({
			"routeName": "/profile-levels-configuration",
			"image": "images/icons/customizable_profile_level.svg",
			"title": "Configuration of the Levels of Expertise",
			"description": "Customize Levels of Expertise"
		})

		if (this.currentUser.company.cv_module_enabled) this.upperCards.push({
			"routeName": "/config-profile",
			"image": "images/icons/profile_adjunstment_icon.svg",
			"title": "Profiles",
			"description": "List of Profiles"
		})

		if (this.currentUser.company.hard_skills_module_enabled) this.upperCards.push({
			"routeName": "/tags",
			"image": "images/icons/icono_tag.svg",
			"title": "Tags",
			"description": "List of Test Tags"
		})

		if (this.currentUser.company.hard_skills_module_enabled) this.upperCards.push({
			"routeName": "/difficulties",
			"image": "images/icons/profile_level.svg",
			"title": "Levels of Difficulty",
			"description": "Test Levels of Difficulty"
		})

		if (this.currentUser.company.hard_skills_module_enabled) this.upperCards.push({
			"routeName": "/languages",
			"image": "images/icons/icono_lenguaje.svg",
			"title": "Languages",
			"description": "List of Available Languages"
		})

		if (this.currentUser.company.hard_skills_module_enabled) this.upperCards.push({
			"routeName": "/questions",
			"image": "images/icons/question_validator/logo_validador_preguntas.svg",
			"title": "Questions",
			"description": "Customizable questions"
		})

		if (this.currentUser.company.hard_skills_module_enabled) this.upperCards.push({
			"routeName": "/certifications",
			"image": "images/icons/cert_validator/logo_validador_certificaciones.svg",
			"title": "Certifications",
			"description": "List of Certifications"
		})

		if (this.currentUser.company.evaluation_360_module_enabled) this.upperCards.push({
			"routeName": "/softskills-configuration",
			"image": "images/icons/bei_icon.svg",
			"title": "BEI/360 Evaluation Configuration",
			"description": "Manage BEI/360 Evaluations"
		})*/
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

.justify-content-center {
	justify-content: center !important;
}

.cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
	margin-top: 4rem !important;
	padding: 0;
	padding-left: 5%;
	list-style-type: none;
}

.cards2 {
	margin-top: 3rem !important;
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>
