<template>
    <div class="container">
        <div class="col-sm-12">
            <div class="row mt-3" v-for="(step, index) in steps"  :key="index">
                <div class="d-flex align-items-center" style="margin-bottom: 15px;">
                    <h5>{{ step }}</h5>
                    <template v-if="index == 0">
                        <button class="btn btn-secondary" type="button" v-b-toggle="'toggle-' + index.toString()" @click="changeState(index)">
                            <font-awesome-icon style="display: block;" v-bind:id="'iconShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down"/>
                            <font-awesome-icon style="display: none;" v-bind:id="'iconNotShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                        </button>
                    </template>
                    <template v-else>
                        <button class="btn btn-secondary" type="button" v-b-toggle="'toggle-' + index.toString()" @click="changeState(index)">
                            <font-awesome-icon style="display: none;" v-bind:id="'iconShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down"/>
                            <font-awesome-icon style="display: block;" v-bind:id="'iconNotShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                        </button>
                    </template>
                    
                </div>
                <b-collapse v-bind:id="'toggle-' + index.toString()" :visible="index == 0 ? true : false">
                    <template v-if="index == 0">
                        <form action ref="form" class="form">
                            <div class="form-container technologiesStyle">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label>Name*<b-button class="btn btn-info" v-b-tooltip.hover
                                                title="Name of the evaluation process"><font-awesome-icon
                                                    icon="info-circle" /></b-button></label>
                                        <input class="form-input" type="text" id="name" v-model="bprocess.name">
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-sm-6">
                                        <div class="row form-group">
                                            <label>Start date*</label>
                                            <input class="form-input" type="date" v-model="bprocess.start_date">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row form-group">
                                            <label>End date*</label>
                                            <input class="form-input" type="date" v-model="bprocess.end_date" :min="bprocess.start_date">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="row mt-4">
                                            <div class="col-sm-5 form-group-progradum">
                                                <label>
                                                    CV Results Enabled*
                                                    <b-button class="btn btn-info" v-b-tooltip.hover title="Activate cv results module">
                                                        <font-awesome-icon icon="info-circle" />
                                                    </b-button>
                                                </label>
                                            </div>
                                            <div class="col-sm-3 form-group-progradum">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label>
                                                            Min Score:
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input class="form-input" max="100" min="0" type="number" v-model="cvExtractorMinScore"
                                                            :disabled="bprocess.cv_extractor_enabled ? false : true">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3 form-group-progradum">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label>
                                                            Weight (%):
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input class="form-input" max="100000" min="0" type="number" v-model="bprocess.cv_extractor_weight"
                                                            :disabled="bprocess.cv_extractor_enabled ? false : true">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-1 form-group-progradum">
                                                <div class="form-check form-switch dichotomic-switch config-switch">
                                                    <input class="form-check-input checkbox-required" type="checkbox"
                                                        v-model="bprocess.cv_extractor_enabled" disabled>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="form-group-progradum col-sm-5">
                                                <label>
                                                    Hard Skills Enabled*
                                                    <b-button class="btn btn-info" v-b-tooltip.hover title="Activate hard skills module">
                                                        <font-awesome-icon icon="info-circle" />
                                                    </b-button>
                                                </label>
                                            </div>

                                            <div class="form-group-progradum col-sm-3">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label>
                                                            Min Score:
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input class="form-input" max="100" min="0" type="number" v-model="bprocess.hard_skills_min_score"
                                                            :disabled="bprocess.hard_skills_enabled ? false : true">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-progradum col-sm-3">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label>
                                                            Weight (%):
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input class="form-input" max="100000" min="0" type="number" v-model="bprocess.hard_skills_weight"
                                                            :disabled="bprocess.hard_skills_enabled ? false : true">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-progradum col-sm-1">
                                                <div class="form-check form-switch dichotomic-switch config-switch">
                                                    <input class="form-check-input checkbox-required" type="checkbox"
                                                        v-model="bprocess.hard_skills_enabled">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="form-group-progradum col-sm-5">
                                                <label>
                                                    BEI/360 Evaluation Enabled*
                                                    <b-button class="btn btn-info" v-b-tooltip.hover
                                                        title="Activate BEI/360 Evaluation module">
                                                        <font-awesome-icon icon="info-circle" />
                                                    </b-button>
                                                </label>
                                            </div>
                                            <div class="form-group-progradum col-sm-3">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label>
                                                            Min Score:
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input v-if="!bprocess.evaluation_360_enabled || typeOfProcess === 'candidate'"
                                                            class="form-input" max="100" min="0" type="number" v-model="bprocess.evaluation_360_min_score"
                                                            disabled>
                                                        <input v-else class="form-input" max="100" min="0" type="number" v-model="bprocess.evaluation_360_min_score">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-progradum col-sm-3">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label>
                                                            Weight (%):
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input v-if="!bprocess.evaluation_360_enabled || typeOfProcess === 'candidate'"
                                                            class="form-input" max="100000" min="0" type="number" v-model="bprocess.evaluation_360_weight"
                                                            disabled>

                                                        <input v-else class="form-input" max="100000" min="0" type="number" v-model="bprocess.evaluation_360_weight">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-progradum col-sm-1">
                                                <div class="form-check form-switch dichotomic-switch config-switch">
                                                    <input v-if="typeOfProcess === 'candidate'" class="form-check-input checkbox-required"
                                                        type="checkbox" v-model="bprocess.evaluation_360_enabled" disabled>
                                                    <input v-else class="form-check-input checkbox-required" type="checkbox"
                                                        v-model="bprocess.evaluation_360_enabled">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="form-group-progradum col-sm-5">
                                                <label>
                                                    Soft Skills Enabled*
                                                    <b-button class="btn btn-info" v-b-tooltip.hover title="Activate soft skills module">
                                                        <font-awesome-icon icon="info-circle" />
                                                    </b-button>
                                                </label>
                                            </div>
                                            <div class="form-group-progradum col-sm-3">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label>
                                                            Min Score:
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input class="form-input" max="100" min="0" type="number" v-model="bprocess.soft_skills_min_score"
                                                            :disabled="bprocess.soft_skills_enabled ? false : true">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-progradum col-sm-3">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label>
                                                            Weight (%):
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input class="form-input" max="100000" min="0" type="number" v-model="bprocess.soft_skills_weight"
                                                            :disabled="bprocess.soft_skills_enabled ? false : true">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-progradum col-sm-1">
                                                <div class="form-check form-switch dichotomic-switch config-switch">
                                                    <input class="form-check-input checkbox-required" type="checkbox"
                                                        v-model="bprocess.soft_skills_enabled" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="form-group col-12">
                                        <div class="row">
                                            <div class="col-sm-auto">
                                                <label>Profile*<b-button class="btn btn-info" v-b-tooltip.hover
                                                        title="Profile associated to the process"><font-awesome-icon
                                                            icon="info-circle" /></b-button></label>
                                            </div>
                                            <div class="col-sm-10">
                                                <multiselect id="profile_selector" :options="profiles" :close-on-select="true"
                                                    :preserve-search="true" :taggable="true" :preselect-first="false" :option-width="140"
                                                    placeholder="Search or add a profile to the evaluation process" v-model="profile">
                                                    <template slot="singleLabel" slot-scope="props">
                                                        <img class="option__image selector-img" :src="$awsBucketRoute + props.option.logo">
                                                        <span class="option__desc"><span class="option__title">{{ props.option.name
                                                                }}</span></span>
                                                    </template>
                                                    <template slot="option" slot-scope="props">
                                                        <img class="option__image selector-img" :src="$awsBucketRoute + props.option.logo">
                                                        <span class="option__desc"><span class="option__title">{{ props.option.name
                                                                }}</span></span>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-sm-4">
                                        <div class="row">
                                            <strong class="radio-button-title float-left" style="width: auto;">Type of process*</strong>
                                        </div>
                                        <div class="row ml-4">
                                            <div class="form-check">
                                                <input class="form-check-input radio-button-input"
                                                    style="margin-left: 0 !important; width: 16px !important;" type="radio"
                                                    name="exampleRadios1" id="exampleRadios1" value="user" v-model="typeOfProcess">
                                                <label class="form-check-label radio-button-label"
                                                    style="float: left; margin-left: 3% !important;" for="exampleRadios1">
                                                    Internal Evaluation Process
                                                </label>
                                            </div>
                                            <div class="form-check mb-3">
                                                <input class="form-check-input radio-button-input"
                                                    style="margin-left: 0 !important; width: 16px !important;" type="radio"
                                                    name="exampleRadios2" id="exampleRadios2" value="candidate" v-model="typeOfProcess">
                                                <label class="form-check-label radio-button-label"
                                                    style="float: left; margin-left: 3% !important;" for="exampleRadios2">
                                                    External Evaluation Process
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-1 mb-3" v-if="typeOfProcess === 'candidate'">
                                    <div class="col-sm-12">
                                        <p class="alert alert-primary alert-profile alert-interview">
                                            The BEI/360 Evaluation module is only available for internal selection processes and has
                                            therefore been
                                            deactivated.
                                        </p>
                                    </div>
                                </div>

                                <div class="row justify-content-center mt-1" v-if="typeOfProcess !== null">
                                    <div class="form-group col-12">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label v-if="typeOfProcess === 'user'">Users to add to the process*<b-button
                                                        class="btn btn-info" v-b-tooltip.hover
                                                        :title="'Users from ' + currentAreaName + ' to include. If the user has no associated CV, it will not be shown as an option to choose.'"><font-awesome-icon
                                                            icon="info-circle" /></b-button></label>
                                                <label v-else>Candidates to add to the process*<b-button class="btn btn-info"
                                                        v-b-tooltip.hover
                                                        :title="'External candidates to include. If the candidate has no associated CV, it will not be shown as an option to choose.'"><font-awesome-icon
                                                            icon="info-circle" /></b-button></label>
                                            </div>
                                            <div class="col-sm-3">
                                                <label style="float: right !important;">Sort by:</label>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="form-check"
                                                    style="justify-content: center !important; display: flex !important;">
                                                    <input class="form-check-input radio-button-input"
                                                        style="margin-left: 0 !important; width: 16px !important;" type="radio"
                                                        name="exampleRadios3" id="exampleRadios3" value="top" v-model="sortBy">
                                                    <label class="form-check-label radio-button-label"
                                                        style="float: left; margin-left: 3% !important;" for="exampleRadios3">
                                                        Top Profile
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-3" v-if="profile !== undefined && profile !== null && profile !== ''">
                                                <div class="form-check">
                                                    <input class="form-check-input radio-button-input"
                                                        style="margin-left: 0 !important; width: 16px !important;" type="radio"
                                                        name="exampleRadios4" id="exampleRadios4" value="specified" v-model="sortBy">
                                                    <label class="form-check-label radio-button-label"
                                                        style="float: left; margin-left: 3% !important;" for="exampleRadios4">
                                                        Selected profile ({{ profile.name }})
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-7">
                                                <label style="float: right !important;">Select top:</label>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="form-check"
                                                    style="justify-content: center !important; display: flex !important;">
                                                    <input class="form-check-input radio-button-input"
                                                        style="margin-left: 0 !important; width: 65px !important;" type="number"
                                                        name="exampleInput" id="exampleInput" v-model="selectTop">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <multiselect id="user_selector" v-if="typeOfProcess === 'user'" :options="userList"
                                                    :multiple="true" :close-on-select="false" :preserve-search="true" :taggable="true"
                                                    :preselect-first="false" :group-select="true"
                                                    placeholder="Search or add a user to the evaluation process" v-model="selectedUsers"
                                                    label="label" track-by="value" group-values="sub_options" group-label="option"
                                                    @select="selectOptionUser" @remove="removeOptionUser"></multiselect>

                                                <multiselect id="candidate_selector" v-else :options="candidateList" :multiple="true"
                                                    :close-on-select="false" :preserve-search="true" :taggable="true"
                                                    :preselect-first="false" :group-select="true"
                                                    placeholder="Search or add a candidate to the evaluation process"
                                                    v-model="selectedCandidates" label="label" track-by="value" group-values="sub_options"
                                                    group-label="option" @select="selectOptionCandidate" @remove="removeOptionCandidate">
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12">
                                        <b-table ref="usersTable" id="usersList" v-if="typeOfProcess === 'user'" :fields="headers"
                                            :items="tableUsers" select-mode="multi" responsive="sm">
                                            <template #cell(image)="data">
                                                <img v-if="data.item.data.image == null" :src="userImage" height="30em"
                                                    class="analysis-card-avatar--circle" />
                                                <img v-else-if="data.item.data.image.includes('null')" :src="userImage" height="30em"
                                                    class="analysis-card-avatar--circle" />
                                                <img v-else-if="data.item.data.image.includes($awsBucketRoute)"
                                                    v-bind:src="data.item.data.image" height="30em" class="analysis-card-avatar--circle" />
                                                <img v-else v-bind:src="$awsBucketRoute + data.item.data.image" height="30em"
                                                    class="analysis-card-avatar--circle" />
                                            </template>
                                            <template #cell(name)="data">
                                                {{ data.item.data.name }}
                                            </template>
                                            <template #cell(top_profile)="data">
                                                {{ data.item.top_profile.toUpperCase() }} ({{ data.item.top_profile_score }}%)
                                            </template>
                                            <template #cell(business_process_profile)="data">
                                                <template v-if="data.item.profile_name !== null && data.item.profile_score !== null">
                                                    {{ data.item.profile_name.toUpperCase() }} ({{ data.item.profile_score }}%)
                                                </template>
                                                <template v-else>
                                                    No selection process profile selected
                                                </template>
                                            </template>
                                        </b-table>

                                        <b-table ref="candidatesTable" id="candidatesList" v-else :fields="headers" :items="tableCandidates"
                                            select-mode="multi" responsive="sm">
                                            <template #cell(image)="data">
                                                <img :src="userImage" height="30em" class="analysis-card-avatar--circle" />
                                            </template>
                                            <template #cell(name)="data">
                                                {{ data.item.data.name }}
                                            </template>
                                            <template #cell(top_profile)="data">
                                                {{ data.item.top_profile.toUpperCase() }} ({{ data.item.top_profile_score }}%)
                                            </template>
                                            <template #cell(business_process_profile)="data">
                                                <template v-if="data.item.profile_name !== null && data.item.profile_score !== null">
                                                    {{ data.item.profile_name.toUpperCase() }} ({{ data.item.profile_score }}%)
                                                </template>
                                                <template v-else>
                                                    No selection process profile selected
                                                </template>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </template>
                    <template v-else-if="index == 1">
                        <div class="col-md-12 technologiesStyle my-2">
                            <template v-if="bprocess.hard_skills_enabled">
                                <template v-if="tests.length > 0">
                                    <div class="row" style="margin-top: 1%;">
                                        <div class="col-sm-9"></div>
                                        <div class="col-sm-3">
                                            <b-form-input id="filter-input" v-model="filter" type="search"
                                                :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                                        </div>
                                    </div>

                                    <b-table v-if="show" id="testsList" :fields="headersTests"
                                        :items="filterTests()" :filter="filter" select-mode="multi" responsive="sm" ref="testsList"
                                        :tbody-tr-class="rowClass">
                                        <template #cell(selected)="{ rowSelected }">
                                            <template v-if="rowSelected">
                                                <span aria-hidden="true">&check;</span>
                                                <span class="sr-only">Selected</span>
                                            </template>
                                            <template v-else>
                                                <span aria-hidden="true">&nbsp;</span>
                                                <span class="sr-only">Not Selected</span>
                                            </template>
                                        </template>
                                        <template #cell(title)="data">
                                            {{ data.item.title }}
                                            <ul class="ti-tags small ps-0">
                                                <li class="ti-tag ti-valid" v-for="item in data.item.tags" v-bind:key="item.id">
                                                    <div class="ti-content">
                                                        <div class="ti-tag-center">
                                                            <span>{{ item.name }}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </template>
                                        <template #cell(description)="data">
                                            <div v-if="data.item.description != null && data.item.description != ''">
                                                {{ truncatedDescription(data.item.description) }}
                                            </div>
                                            <div v-else>
                                                No description provided
                                            </div>
                                        </template>
                                        <template #cell(done)="data">
                                            {{ data.item.done }}
                                        </template>
                                        <template #cell(average_qualification)="data">
                                            <div v-if="data.item.average_qualification != null || data.item.average_qualification == ''">
                                                {{ data.item.average_qualification.toFixed(2) }}
                                            </div>
                                            <div v-else>
                                                Not available
                                            </div>
                                        </template>
                                        <template #cell(difficulty_level)="data">
                                            <p v-if="data.item.numberQuestions != 0" class="difficultyLevelTag"
                                                :style="'background-color: ' + colorDiff[data.item.difficulty_level]">
                                                {{ data.item.difficulty_level.toUpperCase() }}
                                            </p>
                                            <p v-else class="difficultyLevelTag"
                                                :style="'background-color: ' + colorDiff[data.item.difficulty_level]">
                                                {{ data.item.difficulty_level.toUpperCase() }}
                                            </p>
                                        </template>
                                        <template #cell(options)="data">
                                            <button v-b-tooltip.hover title="Dismiss" v-if="selectedItems.filter(item => item.id == data.item.id).length > 0" class="btn btn-secondary"
                                                v-bind:id="'avoid_' + data.index"
                                                @click="dismissTest(data.item, data.index)"><font-awesome-icon icon="ban" /></button>
                                            <button v-b-tooltip.hover title="Add" v-else class="btn btn-secondary"
                                                v-bind:id="'validate_' + data.index"
                                                @click="addTest(data.item, data.index)"><font-awesome-icon icon="plus" /></button>
                                            <button v-b-tooltip.hover title="Show Test" class="btn btn-secondary"
                                                @click="onTestSelected(data.item.id)">
                                                <font-awesome-icon icon="eye" />
                                            </button>
                                        </template>
                                    </b-table>
                                </template>
                                <template v-else>
                                    <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results
                                        found.
                                    </h3>
                                </template>
                            </template>
                            <template v-else>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h5 style="text-align: center !important;">Module disabled.</h5>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else-if="index == 2">
                        <div class="col-md-12 technologiesStyle my-2">
                            <template v-if="bprocess.evaluation_360_enabled">
                                <template v-if="evaluations.length > 0">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h5>Select a BEI/360 Evaluation:</h5>
                                        </div>
                                        <div class="col-sm-9">
                                            <b-form-select style="float: left !important;" v-model="evaluation"
                                                :options="evaluations"></b-form-select>
                                        </div>
                                    </div>
                                    <div class="row" v-if="evaluation !== null" style="margin-top: 1% !important;">
                                        <div class="col-sm-12">
                                            <h5>Information of the predefined Skills Map</h5>
                                        </div>
                                        <div class="row">
                                            <div class="container-fluid">
                                                <div class="row" v-for="(group, index) in competencesToEvaluate" :key="group.id">
                                                    <div class="col-12 padding-0">
                                                        <div class="row">
                                                            <div class="col-1">
                                                                <p class="title-groups margin-top-10">Group</p>
                                                            </div>
                                                            <div class="col-10">
                                                                <div class="row">
                                                                    <div class="col-9">
                                                                        <p class="float-left margin-top-1-5">{{ group.name }}</p>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <p class="p-weight-evaluation-group-results">Weight:
                                                                            &nbsp;&nbsp;&nbsp;{{ group.weight }}%</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-1">
                                                                <button class="btn btn-secondary margin-top-10" type="button"
                                                                    v-b-toggle="'toggleG-' + index.toString()"
                                                                    @click="changeShowGroupState(index)">
                                                                    <font-awesome-icon style="display: none;"
                                                                        v-bind:id="'iconShowG-' + index.toString()" class="trash-icon"
                                                                        v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                                    <font-awesome-icon style="display: block;"
                                                                        v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon"
                                                                        v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <b-collapse visible v-bind:id="'toggleG-' + index.toString()">
                                                        <div class="row">
                                                            <div class="col-10 col-centered">
                                                                <div class="row"
                                                                    v-for="(competence, index_competence) in group.selectedCompetences"
                                                                    :key="group.id + '-' + competence.id">
                                                                    <div class="col-12 padding-0">
                                                                        <div class="row">
                                                                            <div class="col-2">
                                                                                <p class="title-groups margin-top-5">Competence</p>
                                                                            </div>
                                                                            <div class="col-9">
                                                                                <div class="row">
                                                                                    <div class="col-9">
                                                                                        <p class="float-left margin-top-1-8">{{
                                competence.name
                            }}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <p class="p-weight-evaluation-competence-results">
                                                                                            Weight:
                                                                                            &nbsp;&nbsp;&nbsp;{{ competence.weight }}%</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-1">
                                                                                <button class="btn btn-secondary margin-top-15"
                                                                                    type="button"
                                                                                    v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()"
                                                                                    @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                                                    <font-awesome-icon style="display: none;"
                                                                                        v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                                        class="trash-icon" v-b-tooltip.hover title="Expand"
                                                                                        icon="chevron-down" />
                                                                                    <font-awesome-icon style="display: block;"
                                                                                        v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                                        class="trash-icon" v-b-tooltip.hover
                                                                                        title="Contract" icon="chevron-up" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <b-collapse visible
                                                                        v-bind:id="'toggleC-' + index.toString() + '-' + index_competence.toString()">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="row align-items-center">
                                                                                    <p class="title-min-level">Levels availables</p>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <b-form-group
                                                                                        :name="'competence-' + index.toString() + '-' + index_competence.toString()"
                                                                                        class="form-group-margin">
                                                                                        <b-list-group>
                                                                                            <b-list-group-item
                                                                                                v-bind:class="competence.min_level - 1 == index_level ? 'radio-button-class active' : 'radio-button-class'"
                                                                                                v-for="(level, index_level) in competence.levels"
                                                                                                :key="level.id">
                                                                                                <p v-if="index_level == 0"
                                                                                                    style="text-align: left !important;">{{
                                level.name }}
                                                                                                </p>
                                                                                                <p v-else
                                                                                                    style="text-align: left !important;">
                                                                                                    Level
                                                                                                    {{ index_level }}:&nbsp;&nbsp;&nbsp;{{
                                level.name }}
                                                                                                </p>
                                                                                            </b-list-group-item>
                                                                                        </b-list-group>
                                                                                    </b-form-group>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-collapse>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-collapse>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="evaluation !== null" style="margin-top: 1% !important;">
                                        <div class="col-sm-12">
                                            <h5>Select evaluators</h5>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-2">
                                                <p class="p-text">External Evaluators: </p>
                                            </div>
                                            <div class="col-5">
                                                <multiselect id="hrtechnician_selector" :options="hrtechniciansToShow" :multiple="true"
                                                    :close-on-select="false" :preserve-search="true" :taggable="true"
                                                    :preselect-first="false" placeholder="Search or add an External Evaluator"
                                                    v-model="selectedHRTechnicians" label="label" track-by="value"
                                                    @select="selectOptionHRTechnicians" @remove="removeOptionHRTechnicians"></multiselect>
                                            </div>
                                            <div class="col-5" v-if="hrtechniciansToAdd.length !== 0">
                                                <div class="row">
                                                    <div class="col-3 padding-10">
                                                        <p class="p-weight-evaluators">Weight</p>
                                                    </div>
                                                    <div class="col-3 padding-0">
                                                        <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                                            v-model="hrtechnicianWeight"></b-input>
                                                    </div>
                                                    <div class="col-1 padding-0">
                                                        <p class="p-percentage-evaluators">%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-2">
                                                <p class="p-text">Responsables: </p>
                                            </div>
                                            <div class="col-5">
                                                <multiselect id="responsable_selector" :options="responsablesToShow" :multiple="true"
                                                    :close-on-select="false" :preserve-search="true" :taggable="true"
                                                    :preselect-first="false" placeholder="Search or add a responsable"
                                                    v-model="selectedResponsables" label="label" track-by="value"
                                                    @select="selectOptionResponsables" @remove="removeOptionResponsables">
                                                </multiselect>
                                            </div>
                                            <div class="col-5" v-if="responsablesToAdd.length !== 0">
                                                <div class="row">
                                                    <div class="col-3 padding-10">
                                                        <p class="p-weight-evaluators">Weight</p>
                                                    </div>
                                                    <div class="col-3 padding-0">
                                                        <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                                            v-model="responsablesWeight"></b-input>
                                                    </div>
                                                    <div class="col-1 padding-0">
                                                        <p class="p-percentage-evaluators">%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-2">
                                                <p class="p-text">Pairs: </p>
                                            </div>
                                            <div class="col-5">
                                                <multiselect id="pair_selector" :options="pairsToShow" :multiple="true"
                                                    :close-on-select="false" :preserve-search="true" :taggable="true"
                                                    :preselect-first="false" placeholder="Search or add a pair" v-model="selectedPairs"
                                                    label="label" track-by="value" @select="selectOptionPairs" @remove="removeOptionPairs">
                                                </multiselect>
                                            </div>
                                            <div class="col-5" v-if="pairsToAdd.length !== 0">
                                                <div class="row">
                                                    <div class="col-3 padding-10">
                                                        <p class="p-weight-evaluators">Weight</p>
                                                    </div>
                                                    <div class="col-3 padding-0">
                                                        <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                                            v-model="pairsWeight"></b-input>
                                                    </div>
                                                    <div class="col-1 padding-0">
                                                        <p class="p-percentage-evaluators">%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-2 mb-3">
                                                <p class="p-text">Colaborators: </p>
                                            </div>
                                            <div class="col-5 mb-3">
                                                <multiselect id="colaborator_selector" :options="colaboratorsToShow" :multiple="true"
                                                    :close-on-select="false" :preserve-search="true" :taggable="true"
                                                    :preselect-first="false" placeholder="Search or add a colaborator"
                                                    v-model="selectedColaborators" label="label" track-by="value"
                                                    @select="selectOptionColaborators" @remove="removeOptionColaborators">
                                                </multiselect>
                                            </div>
                                            <div class="col-5 mb-3" v-if="colaboratorsToAdd.length !== 0">
                                                <div class="row">
                                                    <div class="col-3 padding-10">
                                                        <p class="p-weight-evaluators">Weight</p>
                                                    </div>
                                                    <div class="col-3 padding-0">
                                                        <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                                            v-model="colaboratorsWeight"></b-input>
                                                    </div>
                                                    <div class="col-1 padding-0">
                                                        <p class="p-percentage-evaluators">%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results
                                        found.
                                    </h3>
                                </template>
                            </template>
                            <template v-else>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h5 style="text-align: center !important;">Module disabled.</h5>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-md-12 technologiesStyle my-2">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h6>If you agree with the settings you have made, click Finish.</h6>
                                    <div id="message" v-if="message != ''" class="alert alert-danger">
                                        {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" /></b-button>
                                    </div>
                                    <button type="button" class="btn btn-primary mb-3" @click="submitForm">Finish form</button>
                                </div>
                            </div>
                        </div>
                    </template>
                </b-collapse>
                <hr v-if="index !== 3">
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';
    import AreasDataService from '@/services/AreasDataService';
    import BusinessProcessService from '@/services/BusinessProcessService';
    import SoftskillsDataService from '@/services/SoftskillsDataService';
    import Multiselect from 'vue-multiselect';
    import BusinessProcess from '@/models/business_process';

    import TestDataService from "@/services/TestDataService";
    import $ from 'jquery'

    import UserDataService from '@/services/UserDataService';

    export default {
        name: 'CreateBP',
        data() {
            return {
                steps: ['Set Configuration', 'Select Tests', 'Add BEI/360 Evaluation', 'Finish form'],
                bprocess: new BusinessProcess('', '', '', [], [], true, true, true, 0),
                currentAreaName: "",
                headers: [
                    { key: 'image', label: '', tdClass: 'tdSmall' },
                    { key: 'name', sortable: true, label: 'Name' },
                    { key: 'top_profile', sortable: true, label: 'Top Profile' },
                    { key: 'business_process_profile', sortable: true, label: 'Evaluation Process Profile' },
                ],
                message: "",
                candidatesToShow: [],
                usersToShow: [],
                usersToAdd: [],
                candidatesToAdd: [],
                tableUsers: [],
                tableCandidates: [],
                userList: [],
                selectedUsers: [],
                candidateList: [],
                selectedCandidates: [],
                logos: [],
                allAvailable: [],
                users: [],
                candidates: [],
                profiles: [],
                userImage: "http://" + location.host + '/images/icons/user.png',
                typeOfProcess: null,
                sortBy: 'top',
                profile: null,
                selectTop: 0,
                cvExtractorMinScore: 20,

                tests: [],
                filter: null,
                difficulties: [],
                colorDiff: {},
                testsId: [],
                difficultyModel: "All",
                selectedItems: [],
                show: false,
                levels: ["All", "Low", "Medium", "High", "Very High"],
                headersTests: [
                    { key: 'title', label: 'Title', sortable: true, tdClass: 'tdBig' },
                    { key: 'description', label: 'Description', tdClass: 'tdBig' },
                    { key: 'done', label: 'Attempts', sortable: true, tdClass: 'tdMedium' },
                    { key: 'average_qualification', label: 'Average Score (Max. score: 100)', sortable: true, tdClass: 'tdBig' },
                    { key: 'difficulty_level', label: 'Level of difficulty', sortable: true, tdClass: 'tdMedium' },
                    { key: 'options', label: 'Options', tdClass: 'tdMedium' }
                ],

                evaluations: [],
                evaluation: null,
                evaluationSelected: null,
                competencesToEvaluate: [],
                filter: null,
                role: 0,
                skillsMap: [],
                competencesByGroups: [],
                allUsersInArea: [],
                users: [],
                hrtechnicians: [],
                responsables: [],
                pairs: [],
                colaborators: [],
                hrtechniciansToShow: [],
                responsablesToShow: [],
                pairsToShow: [],
                colaboratorsToShow: [],
                hrtechniciansToAdd: [],
                responsablesToAdd: [],
                pairsToAdd: [],
                colaboratorsToAdd: [],
                user: 0,
                userObject: {},
                selectedHRTechnicians: [],
                selectedResponsables: [],
                selectedPairs: [],
                selectedColaborators: [],
                hrtechnicianWeight: 0,
                responsablesWeight: 0,
                pairsWeight: 0,
                colaboratorsWeight: 0
            }
        },
        components: {
            Multiselect
        },
        watch: {
            bprocess: {
                handler: function (val) {
                    if (!this.bprocess.cv_extractor_enabled) {
                        this.bprocess.cv_extractor_weight = 0;
                    }

                    if (!this.bprocess.evaluation_360_enabled) {
                        this.bprocess.evaluation_360_weight = 0;

                        this.evaluation = null
                        this.selectedHRTechnicians = []
                        this.hrtechnicianWeight = 0
                        this.hrtechniciansToAdd = []
                        this.selectedResponsables = []
                        this.responsablesWeight = 0
                        this.responsablesToAdd = []
                        this.selectedPairs = []
                        this.pairsWeight = 0
                        this.pairsToAdd = []
                        this.selectedColaborators = []
                        this.colaboratorsWeight = 0
                        this.colaboratorsToAdd = []
                    }

                    if (!this.bprocess.hard_skills_enabled) {
                        this.bprocess.hard_skills_weight = 0;

                        this.selectedItems = []
                    }

                    this.bprocess.cv_extractor_min_score = parseFloat(this.cvExtractorMinScore)
                },
                deep: true
            },
            typeOfProcess() {
                this.selectedCandidates = []
                this.selectedUsers = []
                this.tableCandidates = []
                this.tableUsers = []
                this.selectTop = 0

                if (this.typeOfProcess === 'user') {
                    this.bprocess.type = "internal"
                    this.bprocess.evaluation_360_enabled = true
                } else {
                    this.bprocess.type = "external"
                    this.bprocess.evaluation_360_enabled = false
                }
            },
            profile() {
                let tmpCandidates = [...this.tableCandidates]
                this.tableCandidates = []
                this.selectedCandidates = []
                let tmpUsers = [...this.tableUsers]
                this.tableUsers = []
                this.selectedUsers = []
                this.selectTop = 0

                this.users.forEach(user => {
                    let keys = Object.keys(user.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            user.profile_score = Object.values(user.progress)[i]
                            user.profile_name = Object.keys(user.progress)[i]
                        }
                    }
                })
                this.userList[0].sub_options.forEach(user => {
                    let keys = Object.keys(user.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            user.profile_score = Object.values(user.progress)[i]
                            user.profile_name = Object.keys(user.progress)[i]

                            if (this.sortBy === 'specified') {
                                user.label = user.name + ' - Selected profile: ' + user.profile_name.toUpperCase() + ' (' + user.profile_score + ')'
                            }
                        }
                    }
                })

                this.candidates.forEach(candidate => {
                    let keys = Object.keys(candidate.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            candidate.profile_score = Object.values(candidate.progress)[i]
                            candidate.profile_name = Object.keys(candidate.progress)[i]
                        }
                    }
                })

                this.candidateList[0].sub_options.forEach(candidate => {
                    let keys = Object.keys(candidate.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            candidate.profile_score = Object.values(candidate.progress)[i]
                            candidate.profile_name = Object.keys(candidate.progress)[i]

                            if (this.sortBy === 'specified') {
                                candidate.label = candidate.name + ' - Selected profile: ' + candidate.profile_name.toUpperCase() + ' (' + candidate.profile_score + ')'
                            }
                        }
                    }
                })

                this.allAvailable.forEach(all => {
                    let keys = Object.keys(all.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            all.profile_score = Object.values(all.progress)[i]
                            all.profile_name = Object.keys(all.progress)[i]
                        }
                    }
                })

                if (this.sortBy === 'specified') {
                    this.users.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                    this.userList[0].sub_options.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                    this.candidates.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                    this.candidateList[0].sub_options.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                    this.allAvailable.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                }

                this.tableCandidates = tmpCandidates
                this.tableUsers = tmpUsers


                this.bprocess.id_profile = this.profile.id;
            },
            sortBy() {
                let tmpCandidates = [...this.tableCandidates]
                this.tableCandidates = []
                let tmpUsers = [...this.tableUsers]
                this.tableUsers = []

                this.users.forEach(user => {
                    let keys = Object.keys(user.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            user.profile_score = Object.values(user.progress)[i]
                            user.profile_name = Object.keys(user.progress)[i]
                        }
                    }
                })
                this.userList[0].sub_options.forEach(user => {
                    let keys = Object.keys(user.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            user.profile_score = Object.values(user.progress)[i]
                            user.profile_name = Object.keys(user.progress)[i]

                            if (this.sortBy === 'specified') {
                                user.label = user.name + ' - Selected profile: ' + user.profile_name.toUpperCase() + ' (' + user.profile_score + ')'
                            } else {
                                user.label = user.name + ' - Top profile: ' + user.top_profile.toUpperCase() + ' (' + user.top_profile_score + ')'
                            }
                        }
                    }
                })

                this.candidates.forEach(candidate => {
                    let keys = Object.keys(candidate.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            candidate.profile_score = Object.values(candidate.progress)[i]
                            candidate.profile_name = Object.keys(candidate.progress)[i]
                        }
                    }
                })

                this.candidateList[0].sub_options.forEach(candidate => {
                    let keys = Object.keys(candidate.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            candidate.profile_score = Object.values(candidate.progress)[i]
                            candidate.profile_name = Object.keys(candidate.progress)[i]

                            if (this.sortBy === 'specified') {
                                candidate.label = candidate.name + ' - Selected profile: ' + candidate.profile_name.toUpperCase() + ' (' + candidate.profile_score + ')'
                            } else {
                                candidate.label = candidate.name + ' - Top profile: ' + candidate.top_profile.toUpperCase() + ' (' + candidate.top_profile_score + ')'
                            }
                        }
                    }
                })

                this.allAvailable.forEach(all => {
                    let keys = Object.keys(all.progress)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
                            all.profile_score = Object.values(all.progress)[i]
                            all.profile_name = Object.keys(all.progress)[i]
                        }
                    }
                })

                if (this.sortBy === 'top') {
                    this.users.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
                    this.userList[0].sub_options.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
                    this.candidates.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
                    this.candidateList[0].sub_options.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
                    this.allAvailable.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)

                } else if (this.sortBy === 'specified') {
                    this.users.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                    this.userList[0].sub_options.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                    this.candidates.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                    this.candidateList[0].sub_options.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                    this.allAvailable.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
                }

                this.tableCandidates = tmpCandidates
                this.tableUsers = tmpUsers

                if (this.sortBy === 'top') {
                    this.tableUsers.sort((a, b) => b.top_profile_score - a.top_profile_score);
                } else {
                    this.tableUsers.sort((a, b) => b.progress[this.profile.name.toString().toLowerCase()] - a.progress[this.profile.name.toString().toLowerCase()]);
                }
            },
            selectTop() {
                if (parseFloat(this.selectTop) > 0) {
                    this.tableUsers = []
                    this.usersToAdd = []
                    this.selectOptionUser(this.userList[0].sub_options.slice(0, parseFloat(this.selectTop)))
                    this.selectedUsers = this.userList[0].sub_options.slice(0, parseFloat(this.selectTop))

                    this.tableCandidates = []
                    this.candidatesToAdd = []
                    this.selectOptionCandidate(this.candidateList[0].sub_options.slice(0, parseFloat(this.selectTop)))
                    this.selectedCandidates = this.candidateList[0].sub_options.slice(0, parseFloat(this.selectTop))
                }
            },
            cvExtractorMinScore() {
                if (this.profile !== undefined && this.profile !== null && this.profile !== '' && this.cvExtractorMinScore !== null && this.cvExtractorMinScore !== undefined && this.cvExtractorMinScore !== '') {
                    this.selectedCandidates = []
                    this.selectedUsers = []
                    this.tableCandidates = []
                    this.tableUsers = []
                    this.selectTop = 0

                    this.userList[0].sub_options = this.usersToShow.filter(user => user.profile_score >= parseFloat(this.cvExtractorMinScore));
                    this.candidateList[0].sub_options = this.candidatesToShow.filter(candidate => candidate.profile_score >= parseFloat(this.cvExtractorMinScore));
                }

                this.bprocess.cv_extractor_min_score = parseFloat(this.cvExtractorMinScore)
            },
            candidatesToAdd() {
                Vue.set(this.bprocess, 'candidates', this.candidatesToAdd);
            },
            usersToAdd() {
                Vue.set(this.bprocess, 'users', this.usersToAdd);
            },
            evaluation() {
                if (this.evaluation !== null) this.getCompetencesToEvaluate(this.evaluation)
            },
            hrtechnicianWeight() {
                let weight = parseFloat(0)
                if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
                if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
                if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
                if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)
            },
            pairsWeight() {
                let weight = 0
                if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
                if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
                if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
                if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)
            },
            colaboratorsWeight() {
                let weight = 0
                if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
                if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
                if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
                if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)
            },
            responsablesWeight() {
                let weight = 0
                if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
                if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
                if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
                if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)
            }
        },
        computed: {
            rows() {
                return this.tests.length
            }
        },
        mounted() {
            this.currentAreaName = this.$store.state.auth.user.areas.filter(area => area.id == this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).map(function (e) { return e.name })[0];
            this.getAvailableCandidates();
            this.getAreaProfiles();

            this.getTestBoard();
            this.getTests();

            this.getEvaluations()
            this.getAllUsersInArea()
        },
        methods: {
            changeState(index) {
                if(document.getElementById('iconShow-' + index.toString()).style.display == "block") {
                    document.getElementById('iconShow-' + index.toString()).style.display = "none"
                    document.getElementById('iconNotShow-' + index.toString()).style.display = "block"
                } else {
                    document.getElementById('iconShow-' + index.toString()).style.display = "block"
                    document.getElementById('iconNotShow-' + index.toString()).style.display = "none"
                }
            },
            getAvailableCandidates() {
                AreasDataService.findUsersAndCandidatesForBusinessProcess(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                    response => {
                        if (response.status == 200) {
                            this.allAvailable = response.data

                            this.users = this.allAvailable.filter(one => one.type == 'user')
                            this.users.forEach((user) => {
                                this.usersToShow.push({
                                    label: user.data.name + ' - Top profile: ' + user.top_profile.toUpperCase() + ' (' + user.top_profile_score + ')',
                                    value: user.data.name + '-' + user.data.id,
                                    name: user.data.name,
                                    id: user.data.id,
                                    email: user.data.email,
                                    progress: user.progress,
                                    top_profile: user.top_profile,
                                    top_profile_score: user.top_profile_score,
                                    profile_score: null,
                                    profile_name: null
                                })
                            });

                            let userListTmp = [{ option: "Select all", sub_options: [] }]
                            userListTmp[0].sub_options = this.usersToShow
                            this.userList = userListTmp;
                            this.selectedUsers = []

                            this.candidates = this.allAvailable.filter(one => one.type == 'candidate')
                            this.candidates.forEach((candidate) => {
                                this.candidatesToShow.push({
                                    label: candidate.data.name + ' - Top profile: ' + candidate.top_profile.toUpperCase() + ' (' + candidate.top_profile_score + ')',
                                    value: candidate.data.name + '-' + candidate.data.id,
                                    name: candidate.data.name,
                                    id: candidate.data.id,
                                    email: candidate.data.email,
                                    progress: candidate.progress,
                                    top_profile: candidate.top_profile,
                                    top_profile_score: candidate.top_profile_score,
                                    profile_score: null,
                                    profile_name: null
                                })
                            });

                            let candidateListTmp = [{ option: "Select all", sub_options: [] }]
                            candidateListTmp[0].sub_options = this.candidatesToShow
                            this.candidateList = candidateListTmp;
                            this.selectedCandidates = []

                            this.users.forEach(user => {
                                user.profile_score = null
                                user.profile_name = null
                            })

                            this.candidates.forEach(candidate => {
                                candidate.profile_score = null
                                candidate.profile_name = null
                            })

                            this.allAvailable.forEach(all => {
                                all.profile_score = null
                                all.profile_name = null
                            })

                            this.users.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
                            this.candidates.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
                            this.allAvailable.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
                        } else {
                            this.message = response.data.message;
                        }
                    },
                    error => {
                        console.error((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        if (error.response.status === 300) {
                            console.error('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            getAreaProfiles() {
                const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
                    response => {
                        if (response.status == 200) {
                            this.profiles = response.data
                            this.profiles.forEach((tech) => {
                                this.logos[tech.name.toLowerCase()] = tech.logo.includes(this.$awsBucketRoute) ? tech.logo : this.$awsBucketRoute + tech.logo
                            });
                        }
                    },
                    error => {
                        console.error((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.error('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            selectOptionUser(option) {
                var parsedOption = JSON.parse(JSON.stringify(option))

                if (parsedOption.length === undefined) {
                    let object = {}
                    object.label = parsedOption.label
                    object.value = parsedOption.value
                    var valueSplit = parsedOption.value.split('-')

                    this.usersToAdd.push(parseInt(valueSplit[1]))

                    let userToAdd = this.users.filter(user => parseInt(valueSplit[1]) == user.data.id)[0]
                    this.tableUsers.push(userToAdd)
                } else {
                    parsedOption.forEach(option => {
                        let object = {}

                        object.label = option.label
                        object.value = option.value
                        var valueSplit = option.value.split('-')

                        this.usersToAdd.push(parseInt(valueSplit[1]))

                        let userToAdd = this.users.filter(user => parseInt(valueSplit[1]) == user.data.id)[0]
                        this.tableUsers.push(userToAdd)
                    })
                }

                if (this.sortBy === 'top') {
                    this.tableUsers.sort((a, b) => b.top_profile_score - a.top_profile_score);
                } else {
                    this.tableUsers.sort((a, b) => b.progress[this.profile.name.toString().toLowerCase()] - a.progress[this.profile.name.toString().toLowerCase()]);
                }
            },
            removeOptionUser(option) {
                var parsedOption = JSON.parse(JSON.stringify(option))

                if (parsedOption.length === undefined) {
                    var valueSplit = parsedOption.value.split('-')
                    const index = this.usersToAdd.indexOf(parseInt(valueSplit[1]));
                    this.usersToAdd.splice(index, 1)

                    this.tableUsers = this.tableUsers.filter(user => user.data.id != parseInt(valueSplit[1]))
                } else {
                    parsedOption.forEach(option => {
                        var valueSplit = option.value.split('-')
                        const index = this.usersToAdd.indexOf(parseInt(valueSplit[1]));
                        this.usersToAdd.splice(index, 1)

                        this.tableUsers = this.tableUsers.filter(user => user.data.id != parseInt(valueSplit[1]))
                    })
                }
            },
            selectOptionCandidate(option) {
                var parsedOption = JSON.parse(JSON.stringify(option))

                if (parsedOption.length === undefined) {
                    let object = {}
                    object.label = parsedOption.label
                    object.value = parsedOption.value
                    var valueSplit = parsedOption.value.split('-')

                    this.candidatesToAdd.push(parseInt(valueSplit[1]))

                    let candidateToAdd = this.candidates.filter(candidate => parseInt(valueSplit[1]) == candidate.data.id)[0]
                    this.tableCandidates.push(candidateToAdd)
                } else {
                    parsedOption.forEach(option => {
                        let object = {}

                        object.label = option.label
                        object.value = option.value
                        var valueSplit = option.value.split('-')

                        this.candidatesToAdd.push(parseInt(valueSplit[1]))

                        let candidateToAdd = this.candidates.filter(candidate => parseInt(valueSplit[1]) == candidate.data.id)[0]
                        this.tableCandidates.push(candidateToAdd)
                    })
                }
            },
            removeOptionCandidate(option) {
                var parsedOption = JSON.parse(JSON.stringify(option))

                if (parsedOption.length === undefined) {
                    var valueSplit = parsedOption.value.split('-')
                    const index = this.candidatesToAdd.indexOf(parseInt(valueSplit[1]));
                    this.candidatesToAdd.splice(index, 1)

                    this.tableCandidates = this.tableCandidates.filter(candidate => candidate.data.id != parseInt(valueSplit[1]))
                } else {
                    parsedOption.forEach(option => {
                        var valueSplit = option.value.split('-')
                        const index = this.candidatesToAdd.indexOf(parseInt(valueSplit[1]));
                        this.candidatesToAdd.splice(index, 1)

                        this.tableCandidates = this.tableCandidates.filter(candidate => candidate.data.id != parseInt(valueSplit[1]))
                    })
                }
            },
            close() {
                this.message = "";
            },

            onTestSelected(testID) {
                let routeData = this.$router.resolve({ name: 'test-edit-questions', query: { id: testID } });
                window.open(routeData.href, '_blank');
            },
            getTestBoard() {
                const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                TestDataService.getTestBoard(currentArea).then(
                    response => {
                        if (response.status == 200) {
                            this.difficulties = response.data.difficulty_levels
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            getTests() {
                const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                TestDataService.findByArea(currentArea).then(
                    response => {
                        if (response.status == 200) {
                            let dataTmp = response.data;
                            let dataTmp2 = []

                            dataTmp.forEach((d) => {
                                if (d.number_questions > 0) {
                                    dataTmp2.push(d)
                                    this.testsId.push(d.id)
                                }
                            })
                            TestDataService.findQuestionsForTestsId(this.testsId).then(
                                response2 => {
                                    if (response2.status == 200) {
                                        let tests = response2.data;

                                        for (var i = 0; i < tests.length; i++) {
                                            var questions = tests[i].questions
                                            var averageDifficulty = 0
                                            var weightSum = 0

                                            questions.forEach((question) => {
                                                var questionWeight = 0;
                                                for (var k = 0; k < this.difficulties.length; k++) {
                                                    if (this.difficulties[k].id == question.id_difficulty_level) {
                                                        questionWeight = this.difficulties[k].level
                                                        break;
                                                    }
                                                }

                                                weightSum = weightSum + questionWeight;
                                            })

                                            if (questions.length > 0)
                                                averageDifficulty = weightSum / questions.length;
                                            else
                                                averageDifficulty = 0;

                                            for (var k = 0; k < dataTmp2.length; k++) {
                                                if (dataTmp2[k].id == tests[i].test.id) {
                                                    dataTmp2[k].averageDifficulty = averageDifficulty.toFixed("2")
                                                    break;
                                                }
                                            }

                                        }

                                        this.tests = dataTmp2

                                        this.tests.forEach(test => {
                                            test.show = false;
                                        });

                                        this.difficulties.forEach((difficulty) => {
                                            this.colorDiff[difficulty.name] = difficulty.color
                                        })

                                        this.show = true;
                                    }
                                },
                                error => {
                                    console.error((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())
                                    
                                    this.message = (error.response && error.response.data) ||
                                        error.message ||
                                        error.toString();

                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        }
                    },
                    error => {
                        console.error((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            addTest(item, index) {
                this.$root.$emit('bv::refresh::table', 'testsList')
                $('#testsList tr ').eq(index + 1).addClass('validated-question');
                item.show = true;
                this.selectedItems.push(item);
            },
            dismissTest(item, index) {
                this.$root.$emit('bv::refresh::table', 'testsList')
                item.show = false;
                $('#testsList tr ').eq(index + 1).removeClass('validated-question');
                this.selectedItems = this.selectedItems.filter((item2) => item2.id !== item.id);
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return;
                if (item.selected) return 'validated-question';
            },
            filterTests() {
                if (this.difficultyModel == "All") {
                    return this.tests;
                } else {
                    return this.tests.filter(field => field.difficulty_level == this.difficultyModel);
                }
            },
            truncatedDescription(description) {
                const maxLength = 128;
                if (description && description.length > maxLength) {
                    return description.slice(0, maxLength) + '...';
                }
                return description;
            },

            getAllUsersInArea() {
                UserDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                    response => {
                        if (200 == response.status) {
                            this.allUsersInArea = response.data;
                            console.log('response.data: ', response.data)
                            this.allUsersInArea = this.allUsersInArea.filter(field => field.role.id != undefined)
                            this.fillEvaluators()
                        } else {
                            this.message = "There was an error while retrieving users from company";
                        }
                    },
                    error => {
                        console.error((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        this.message = "There was an error while retrieving users from company";

                        if (error.response.status === 300) {
                            console.error('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            getEvaluations() {
                const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                SoftskillsDataService.findEvaluationsByArea(currentArea).then(
                    response => {
                        if (response.status == 200) {
                            response.data.forEach(evaluation => {
                                if (evaluation.total_users == 0 && evaluation.total_evaluators == 0) this.evaluations.push({ value: evaluation.id, text: evaluation.name })
                            })

                            console.log('this.evaluations: ', this.evaluations)
                        }
                    },
                    error => {
                        console.error((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString()

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            getCompetencesToEvaluate(id) {
                SoftskillsDataService.findEvaluationSummary(id).then(
                    response => {
                        this.evaluationSelected = response.data;

                        SoftskillsDataService.findCompanyCompetencesDirectory(this.$store.state.auth.user.id_company).then(
                            response => {
                                this.skillsMap = response.data

                                if (this.skillsMap.length > 0) {
                                    this.competencesByGroups = this.skillsMap

                                    if (this.skillsMap[0].type === "group") {
                                        this.evaluationSelected.weights.forEach(weight => {
                                            this.competencesByGroups.forEach(group => {
                                                if (group.id === weight.id_soft_skill) {
                                                    this.competencesToEvaluate.push(group)
                                                }
                                            })
                                        })

                                        this.competencesToEvaluate.forEach(group => {
                                            group.selectedCompetences = []
                                        })

                                        this.evaluationSelected.weights.forEach(weight => {
                                            this.competencesToEvaluate.forEach(group => {
                                                if (group.id === weight.id_soft_skill) {
                                                    group.weight = weight.weight
                                                }

                                                group.competences.forEach(competence => {
                                                    if (competence.id === weight.id_soft_skill) {
                                                        competence.weight = weight.weight
                                                        competence.min_level = weight.min_level
                                                        group.selectedCompetences.push(competence)
                                                    }
                                                })
                                            })
                                        })
                                    }
                                }

                                console.log('competences: ', this.competencesToEvaluate)
                            },
                            error => {
                                console.log((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())
                                
                                this.message = (error.response && error.response.data) ||
                                    error.message ||
                                    error.toString()

                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString()

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            fillEvaluators() {
                this.hrtechnicians = []
                this.hrtechniciansToShow = []
                this.responsables = []
                this.responsablesToShow = []
                this.pairs = []
                this.pairsToShow = []
                this.colaborators = []
                this.colaboratorsToShow = []
                this.userObject = {}

                let userLoggedIn = localStorage.getItem('user')

                this.allUsersInArea.forEach(user => {
                    if (user.id === userLoggedIn) {
                        this.userObject = user
                    }
                })

                console.log('userObject: ', this.userObject)

                const userSelected = userLoggedIn

                this.pairs = this.allUsersInArea

                this.pairs.forEach(user => {
                    this.pairsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                })

                this.allUsersInArea.forEach(user => {
                    if (user.id !== userSelected && Object.keys(this.userObject).length !== 0) {
                        if (user.role.id === 9) {
                            this.hrtechnicians.push(user) // Talent manager
                            this.hrtechniciansToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                        } else if (user.role.id === 1 || user.role.id === 8) {
                            this.responsables.push(user) // Admin, manager
                            this.responsablesToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                        }

                        if (user.role.id !== 3 && user.role.id !== 9) {
                            this.colaborators.push(user)
                            this.colaboratorsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                        }
                    } else if (user.id !== userLoggedIn && Object.keys(this.userObject).length === 0) {
                        if (user.role.id === 9) {
                            this.hrtechnicians.push(user) // Talent manager
                            this.hrtechniciansToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                        } else if (user.role.id === 1 || user.role.id === 8) {
                            this.responsables.push(user) // Admin, manager
                            this.responsablesToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                        }

                        const rolesNotAllowed = [3, 9] //this.role]

                        if (!rolesNotAllowed.includes(user.role.id)) {
                            this.colaborators.push(user)
                            this.colaboratorsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                        }
                    }
                })

                let selectedUsers = JSON.parse(localStorage.getItem('selectedUsers'))

                let hrtechniciansTmp = []
                let responsablesTmp = []
                let pairsTmp = []
                let colaboratorsTmp = []

                this.hrtechniciansToShow.forEach(item => {
                    let idSplit = item.value.split('-')
                    let id = idSplit[1]
                    let exists = false

                    selectedUsers.forEach(user => {
                        if (id == user.id) exists = true
                    })

                    if (!exists) hrtechniciansTmp.push(item)
                })

                this.responsablesToShow.forEach(item => {
                    let idSplit = item.value.split('-')
                    let id = idSplit[1]
                    let exists = false

                    selectedUsers.forEach(user => {
                        if (id == user.id) exists = true
                    })

                    if (!exists) responsablesTmp.push(item)
                })

                this.pairsToShow.forEach(item => {
                    let idSplit = item.value.split('-')
                    let id = idSplit[1]
                    let exists = false

                    selectedUsers.forEach(user => {
                        if (id == user.id) exists = true
                    })

                    if (!exists) pairsTmp.push(item)
                })

                this.colaboratorsToShow.forEach(item => {
                    let idSplit = item.value.split('-')
                    let id = idSplit[1]
                    let exists = false

                    selectedUsers.forEach(user => {
                        if (id == user.id) exists = true
                    })

                    if (!exists) colaboratorsTmp.push(item)
                })

                this.hrtechniciansToShow = hrtechniciansTmp
                this.responsablesToShow = responsablesTmp
                this.pairsToShow = pairsTmp
                this.colaboratorsToShow = colaboratorsTmp
            },
            changeShowGroupState(index) {
                if (document.getElementById('iconShowG-' + index.toString()).style.display == "block") {
                    document.getElementById('iconShowG-' + index.toString()).style.display = "none"
                    document.getElementById('iconNotShowG-' + index.toString()).style.display = "block"
                } else {
                    document.getElementById('iconShowG-' + index.toString()).style.display = "block"
                    document.getElementById('iconNotShowG-' + index.toString()).style.display = "none"
                }
            },
            changeShowCompetenceFromGroupState(index, index_competence) {
                if (document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display == "block") {
                    document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                    document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                } else {
                    document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                    document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                }
            },
            selectOptionHRTechnicians(option) {
                var object = {}

                var parsedOption = JSON.parse(JSON.stringify(option))

                object.label = parsedOption.label
                object.value = parsedOption.value
                var valueSplit = parsedOption.value.split('-')

                this.hrtechniciansToAdd.push(parseInt(valueSplit[1]))
            },
            removeOptionHRTechnicians(option) {
                var parsedOption = JSON.parse(JSON.stringify(option))
                var valueSplit = parsedOption.value.split('-')
                const index = this.hrtechniciansToAdd.indexOf(parseInt(valueSplit[1]));
                this.hrtechniciansToAdd.splice(index, 1)
            },
            selectOptionResponsables(option) {
                var object = {}
                var parsedOption = JSON.parse(JSON.stringify(option))
                object.label = parsedOption.label
                object.value = parsedOption.value
                var valueSplit = parsedOption.value.split('-')
                this.responsablesToAdd.push(parseInt(valueSplit[1]))
            },
            removeOptionResponsables(option) {
                var parsedOption = JSON.parse(JSON.stringify(option))
                var valueSplit = parsedOption.value.split('-')
                const index = this.responsablesToAdd.indexOf(parseInt(valueSplit[1]));
                this.responsablesToAdd.splice(index, 1)
            },
            selectOptionPairs(option) {
                var object = {}
                var parsedOption = JSON.parse(JSON.stringify(option))
                object.label = parsedOption.label
                object.value = parsedOption.value
                var valueSplit = parsedOption.value.split('-')
                this.pairsToAdd.push(parseInt(valueSplit[1]))
            },
            removeOptionPairs(option) {
                var parsedOption = JSON.parse(JSON.stringify(option))
                var valueSplit = parsedOption.value.split('-')
                const index = this.pairsToAdd.indexOf(parseInt(valueSplit[1]));
                this.pairsToAdd.splice(index, 1)
            },
            selectOptionColaborators(option) {
                var object = {}
                var parsedOption = JSON.parse(JSON.stringify(option))
                object.label = parsedOption.label
                object.value = parsedOption.value
                var valueSplit = parsedOption.value.split('-')
                this.colaboratorsToAdd.push(parseInt(valueSplit[1]))
            },
            removeOptionColaborators(option) {
                var parsedOption = JSON.parse(JSON.stringify(option))
                var valueSplit = parsedOption.value.split('-')
                const index = this.colaboratorsToAdd.indexOf(parseInt(valueSplit[1]));
                this.colaboratorsToAdd.splice(index, 1)
            },

            submitForm() {
                if (this.checkErrors()) {
                    this.bprocess.id_area  = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

                    let tests = []
                    let testsIds = []
                    if (this.bprocess.hard_skills_enabled) {
                        this.selectedItems.forEach(test => {
                            testsIds.push(test.id);
                        })
                    }
                    console.log('tests: ', tests)

                    if (this.bprocess.evaluation_360_enabled) {
                        this.bprocess.id_evaluation_360 = this.evaluation
                    }

                    console.log('bprocessToAdd: ', this.bprocess)

                    BusinessProcessService.create(this.bprocess).then(
                        async response => {
                            this.businessProcessId = response.data.id;

                            const tests = [];
                            this.selectedItems.forEach((item) => {
                                tests.push(item.id);
                            });

                            if (testsIds.length > 0) { // El modulo está habilitado
                                await BusinessProcessService.addTestsToBusinessProcess(this.businessProcessId, testsIds).then(
                                    () => {
                                    },
                                    error => {
                                        console.log((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        this.message = (error.response && error.response.data) ||
                                            error.message ||
                                            error.toString();
                                        this.successfull = false;

                                        if (error.response.status === 300) {
                                            console.log('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            }

                            console.log('this.evaluation antes de añadir usuarios: ', this.evaluation)

                            if (this.evaluation !== null) {
                                // Añadir usuarios
                                let usersToAdd = []
                                this.bprocess.users.forEach(user => {
                                    let userToAdd = {
                                        id_user: user,
                                    }

                                    usersToAdd.push(userToAdd)
                                });

                                console.log('usersToAdd: ', usersToAdd)

                                await SoftskillsDataService.addUsersToEvaluation(usersToAdd, this.evaluation, this.businessProcessId).then(
                                    async response => {
                                        if (200 == response.status) {
                                            console.log('usuarios añadidos')
                                            // Añadir evaluadores
                                            let evaluatorsToAdd = [];

                                            this.selectedHRTechnicians.forEach(hrtechnician => {
                                                let hrtechnicianValueSplitted = hrtechnician.value.split('-')
                                                let evaluator = {
                                                    id_evaluator: parseInt(hrtechnicianValueSplitted[1]),
                                                    role: 'hr_technician',
                                                    id_evaluation: this.evaluation,
                                                    weight: parseFloat(this.hrtechnicianWeight / this.selectedHRTechnicians.length),
                                                    done: 0
                                                }

                                                evaluatorsToAdd.push(evaluator)
                                            })

                                            this.selectedResponsables.forEach(responsable => {
                                                let responsableValueSplitted = responsable.value.split('-')
                                                let evaluator = {
                                                    id_evaluator: parseInt(responsableValueSplitted[1]),
                                                    role: 'responsible',
                                                    id_evaluation: this.evaluation,
                                                    weight: parseFloat(this.responsablesWeight / this.selectedResponsables.length),
                                                    done: 0
                                                }

                                                evaluatorsToAdd.push(evaluator)
                                            })

                                            this.selectedPairs.forEach(pair => {
                                                let pairValueSplitted = pair.value.split('-')
                                                let evaluator = {
                                                    id_evaluator: parseInt(pairValueSplitted[1]),
                                                    role: 'pair',
                                                    id_evaluation: this.evaluation,
                                                    weight: parseFloat(this.pairsWeight / this.selectedPairs.length),
                                                    done: 0
                                                }

                                                evaluatorsToAdd.push(evaluator)
                                            })

                                            this.selectedColaborators.forEach(colaborator => {
                                                let colaboratorValueSplitted = colaborator.value.split('-')
                                                let evaluator = {
                                                    id_evaluator: parseInt(colaboratorValueSplitted[1]),
                                                    role: 'collaborator',
                                                    id_evaluation: this.evaluation,
                                                    weight: parseFloat(this.colaboratorsWeight / this.selectedColaborators.length),
                                                    done: 0
                                                }

                                                evaluatorsToAdd.push(evaluator)
                                            })

                                            console.log('evaluatorsToAdd antes de añadir evaluadores: ', evaluatorsToAdd)
                                            
                                            await SoftskillsDataService.addEvaluatorsToEvaluation(evaluatorsToAdd, this.evaluation, this.businessProcessId).then(
                                                response => {
                                                    if (200 == response.status) {
                                                        console.log('evaluadores añadidos')
                                                    } else {
                                                        this.successful = false
                                                        this.message = 'Error while adding evaluators to the evaluation'
                                                    }
                                                },
                                                error => {
                                                    console.error((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())

                                                    this.successful = false
                                                    this.message = 'There was an error while adding evaluators to the evaluation'

                                                    if (error.response.status === 300) {
                                                        console.error('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    } else {
                                                        return [];
                                                    }
                                                }
                                            );
                                        } else {
                                            this.message = 'Error while adding users to the evaluation'
                                            this.successful = false
                                        }
                                    },
                                    error => {
                                        console.error((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        this.message = 'There was an error while adding weights to the evaluation'
                                        this.successful = false

                                        if (error.response.status === 300) {
                                            console.error('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            }

                            if (this.bprocess.type === 'internal') {
                                await BusinessProcessService.addUsersToBusinessProcess(response.data.id, this.bprocess.users).then(
                                    () => {
                                        this.$router.push('/business-process');
                                    },
                                    error => {
                                        console.log((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        if (error.response.status === 300) {
                                            console.log('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            } else {
                                await BusinessProcessService.addCandidatesToBusinessProcess(response.data.id, this.bprocess.candidates).then(
                                    () => {
                                        this.$router.push('/business-process');
                                    },
                                    error => {
                                        console.log((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        if (error.response.status === 300) {
                                            console.log('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            }
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            this.message = error.response.data.message.replace("Bad Request.", "");

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }
            },
            checkErrors() {
                if (this.bprocess.name.toString().trim() == '' || this.bprocess.start_date.toString().trim() == '' || this.bprocess.end_date.toString().trim() == '') {
                    this.message = 'Please, fill all fields.'
                    return false
                }

                if (!this.bprocess.cv_extractor_enabled && !this.bprocess.evaluation_360_enabled && !this.bprocess.hard_skills_enabled) {
                    this.message = 'Please, select, at least, one module'
                    return false;
                }

                if (this.bprocess.cv_extractor_enabled && (this.bprocess.cv_extractor_min_score.toString().trim() == '' || this.bprocess.cv_extractor_weight.toString().trim() == '' || this.bprocess.cv_extractor_weight.toString().trim() == '0')) {
                    this.message = 'Please, fill all fields.'
                    return false;
                }

                if (this.bprocess.hard_skills_enabled && (this.bprocess.hard_skills_min_score.toString().trim() == '' || this.bprocess.hard_skills_weight.toString().trim() == '' || this.bprocess.hard_skills_weight.toString().trim() == '0')) {
                    this.message = 'Please, fill all fields.'
                    return false;
                }

                if (this.bprocess.evaluation_360_enabled && (this.bprocess.evaluation_360_min_score.toString().trim() == '' || this.bprocess.evaluation_360_weight.toString().trim() == '' || this.bprocess.evaluation_360_weight.toString().trim() == '0')) {
                    this.message = 'Please, fill all fields.'
                    return false;
                }

                let sumWeights = 0
                if (this.bprocess.cv_extractor_enabled) sumWeights += parseInt(this.bprocess.cv_extractor_weight)
                if (this.bprocess.hard_skills_enabled) sumWeights += parseInt(this.bprocess.hard_skills_weight)
                if (this.bprocess.evaluation_360_enabled) sumWeights += parseInt(this.bprocess.evaluation_360_weight)

                if (sumWeights !== 100) {
                    this.message = "Please, check that total modules weight sum 100.";
                    return false;
                }

                if (this.bprocess.id_profile == undefined || this.bprocess.id_profile == null || this.bprocess.id_profile == 0) {
                    this.message = "Please, select a profile.";
                    return false;
                }

                if (this.bprocess.type == undefined || this.bprocess.type == null || (this.bprocess.type != 'internal' && this.bprocess.type != 'external')) {
                    this.message = "Please, select the business process type.";
                    return false;
                }

                if (this.bprocess.type == 'internal') {
                    if (this.bprocess.users == null || this.bprocess.users == undefined || this.bprocess.users.length == 0) {
                        this.message = "Please, select, at least, one user.";
                        return false;
                    }
                } else {
                    if (this.bprocess.candidates == null || this.bprocess.candidates == undefined || this.bprocess.candidates.length == 0) {
                        this.message = "Please, select, at least, one candidate.";
                        return false;
                    }
                }

                if (this.bprocess.hard_skills_enabled) {
                    if (this.selectedItems.length == 0) {
                        this.message = "Please, select, at least, one test."
                        return false
                    }
                }

                if (this.bprocess.evaluation_360_enabled) {
                    let sumEvaluationWeights = 0

                    if (this.evaluation == null) {
                        this.message = 'Please, select an evaluation.'
                        return false
                    }

                    if (this.selectedHRTechnicians.length == 0 && this.selectedColaborators.length == 0 && this.selectedPairs.length == 0 && this.selectedResponsables.length == 0) {
                        this.message = 'Please, select, at least, one type of evaluator.'
                        return false
                    }

                    if (this.selectedHRTechnicians.length > 0 && this.hrtechnicianWeight.toString().trim() == '') {
                        this.message = 'Please, fill all fields.'
                        return false
                    }

                    if (this.selectedColaborators.length > 0 && this.colaboratorsWeight.toString().trim() == '') {
                        this.message = 'Please, fill all fields.'
                        return false
                    }

                    if (this.selectedPairs.length > 0 && this.pairsWeight.toString().trim() == '') {
                        this.message = 'Please, fill all fields.'
                        return false
                    }

                    if (this.selectedResponsables.length > 0 && this.responsablesWeight.toString().trim() == '') {
                        this.message = 'Please, fill all fields.'
                        return false
                    }

                    if (this.selectedHRTechnicians.length > 0) sumEvaluationWeights += parseFloat(this.hrtechnicianWeight)
                    if (this.selectedColaborators.length > 0) sumEvaluationWeights += parseFloat(this.colaboratorsWeight)
                    if (this.selectedPairs.length > 0) sumEvaluationWeights += parseFloat(this.pairsWeight)
                    if (this.selectedResponsables.length > 0) sumEvaluationWeights += parseFloat(this.responsablesWeight)

                    if (sumEvaluationWeights !== 100) {
                        this.message = "Please, check that total evaluators weights sum 100.";
                        return false;
                    }
                }

                return true
            }
        }
    }

</script>

<style type="text/css" scoped>
.selector-img {
	width: 8% !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>