<template>
	<div class="row" v-if="!pdf">
		<div class="row">
			<div class="col-sm-4">
				<div class="card-image">
					<img v-bind:src="userImage" class="card-avatar-analysis-profile card-avatar--circle"
						@error="imageLoadError($event)">
				</div>
			</div>
			<div class="col-sm-4">
				<div class="row mt-4">
					<div class="col-sm-5  ">
						<h3 id="title-analysis-profile">{{ name }} </h3>
					</div>
					<div class="col-sm-7 float-left">
						<p class="profileLevelTag" :style="'background-color: ' + currentProfileLevelColor + '; color : ' + getContrastColor(currentProfileLevelColor)">
							{{ topProfileName }} {{ topProfileLevel }}</p>
					</div>
				</div>
				<div class="row" v-if="anonymized">
					<div class="col-sm-12 float-left mb-2">
						<button v-b-tooltip.hover :title="token" class="btn btn-secondary float-left"
							style="padding: 0 !important;" type="button" @click="copyToClipboard(token)">Copy
							token</button>
					</div>
				</div>
				<div class="row">
					<h5 class="subtitle-analysis-profile">{{ lastJob }}</h5>
				</div>
				<div class="row basr-social-share social">
					<ul>
						<li v-for="(link, index) in links" v-bind:key="index"
							v-if="containsAny(link, Object.keys(linkTypes)) != null" @click="redirectExternalLink(link)"
							style="text-decoration: underline">
							<a class="inline-tag-icons">
								<img class="icon-link-set"
									:src="linkTypes[containsAny(link, Object.keys(linkTypes))]" />
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-sm-4" v-show="isAdmin || isTM">
				<div class="row">
					<div class="col-sm-6">
						<label class="form-check-label" for="gridCheck">
							Mark this CV as pending review
						</label>
						<input class="form-check-input" v-model="validated" type="checkbox" id="gridCheck">
					</div>

					<div class="row form-group align-item-center">
						<div class="col-sm-12">
							<div class="row">
								<label class="form-check-label" for="gridCheck">
									Add a comment
								</label>
							</div>
							<div class="row">
								<textarea class="form-control textarea-message" v-model="adminComments"
									id="exampleFormControlTextarea1" rows="3"></textarea>
							</div>
							<div class="row">
								<button v-b-tooltip.hover title="Save Changes" @click="saveChanges"
									class="btn btn-secondary" type="button">Save Changes</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-3" style="padding: 0 !important;"></div>
			<div class="col-sm-6" style="padding: 0 !important;">
				<b-alert :show="dismissCountDownSuccess" dismissible variant="success"
					@dismissed="dismissCountDownSuccess = 0" @dismiss-count-down="countDownChangedSuccess"
					style="margin: 0 !important;">
					<h5 class="align-items-center mb-3">Token copied!</h5>
					<b-progress variant="success" :max="dismissSecsSuccess" :value="dismissCountDownSuccess"
						style="height: 2px !important"></b-progress>
				</b-alert>
				<b-alert :show="dismissCountDownError" dismissible variant="error"
					@dismissed="dismissCountDownError = 0" @dismiss-count-down="countDownChangedError"
					style="margin: 0 !important;">
					<h5 class="align-items-center mb-3">Error copying token!</h5>
					<b-progress variant="error" :max="dismissSecsError" :value="dismissCountDownError"
						style="height: 2px !important"></b-progress>
				</b-alert>
			</div>
			<div class="col-sm-3" style="padding: 0 !important;"></div>
		</div>
	</div>
	<div class="row no-gutter" style="--bs-gutter-x: 0 !important" v-else>
		<h2
			style="background-color: #04243b; color: white; font-size: 19.66px; padding: 4px; padding-left: 13px;font-weight: 600 !important; margin-bottom: 0px">
			INFORME DE CANDIDATO</h2>
		<div class="col-sm-2" v-if="!anonymized" style="margin-top: 45px; padding-left: 0px">
			<div class="card-image">
				<img v-bind:src="userImage" class="card-avatar-analysis-profile card-avatar--circle"
					style="width: 100px" @error="imageLoadError($event)" v-if="!anonymize">
				<img v-bind:src="'http://' + this.currentUrl + '/images/icons/user.png'"
					class="card-avatar-analysis-profile card-avatar--circle" style="width: 100px"
					@error="imageLoadError($event)" v-else>
			</div>
		</div>
		<div :class="anonymized ? 'col-sm-9' : 'col-sm-7'" style="margin-top: 40px">
			<div class="row mt-2">
				<div class="col-sm-7  ">
					<h3 v-if="!anonymize" id="title-analysis-profile"
						style="font-size: 18.66px; margin-bottom: 0px; color: #13283B">{{ name }} </h3>
					<h3 v-else id="title-analysis-profile"
						style="font-size: 18.66px; margin-bottom: 0px; color: #13283B"><i>Anonymized</i></h3>
				</div>
			</div>
			<div class="row">
				<h5 v-if="!anonymize" class="subtitle-analysis-profile" style="font-size: 14.66px; color: #13283B">
					{{ lastJob }}</h5>
				<h5 v-else class="subtitle-analysis-profile" style="font-size: 14.66px; color: #13283B">
					{{ lastJobAnonymized }}</h5>
			</div>
			<div class="row">
				<div class="col-sm-10">
					<p class="profileLevelTag"
						:style="'background-color: ' + currentProfileLevelColor + ';margin-bottom: 0;' + 'font-size: 14px; width: 42%; text-align: center; font-weight: 600'">
						{{ topProfileName }} {{ topProfileLevel }}</p>
				</div>
			</div>
		</div>
		<div class="col-sm-3">
			<div class="card-image" style="float: right; height: 84%; margin-right: 16px; display: flex">
				<img style="width: 60%; margin: auto;" v-bind:src="company_img + '?withheaders=yes'"
					class="card-avatar-analysis-profile">
			</div>
		</div>
		<div class="row" style="margin-top: -26px" v-if="!anonymize">
			<div class="col-sm-12">
				<a style="text-decoration: none !important; color: #13283b;" :href="'/profile-analysis?id=' + data.id"
					target="_blank">
					<p class="profileLevelTag"
						:style="'background-color: rgb(60 195 234);margin-bottom: 0;' + 'font-size: 14px; width: 23%; text-align: center; font-weight: 600; margin-left: 75%; margin-top: 0px'">
						Perfil interactivo <b>></b></p>
				</a>
				<small style="margin-left: 77%; font-size: 10.5px">Acceso exclusivo usuarios</small>
			</div>
		</div>
	</div>
</template>

<script>
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import UserDataService from '@/services/UserDataService';
import CompanyDataService from '@/services/CompanyDataService';

export default {
	name: "UserBasicInformation",
	data() {
		return {
			currentUrl: location.host,
			validated: false,
			adminComments: "",
			completedSteps: 82,
			totalSteps: 100,
			dismissSecsSuccess: 4,
			dismissSecsError: 4,
			dismissCountDownSuccess: 0,
			dismissCountDownError: 0,
			company_img: ''
		}
	},
	props: {
		name: String,
		anonymized: Boolean,
		token: String,
		userImage: String,
		currentProfileLevelColor: String,
		topProfileName: String,
		topProfileLevel: String,
		lastJob: String,
		lastJobAnonymized: String,
		links: Array,
		data: Object,
		anonymize: Boolean,
		pdf: {
			type: Boolean,
			default: false
		}
	},
	components: {
	},
	computed: {
		linkTypes() {
			return this.$linkTypes;
		},
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		},
		isTM() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'talent_manager' || this.currentUser.role.name === 'manager') {
					return true;
				}
			}
			return false
		},
	},
	mounted() {
		ProfileAnalysisDataService.findAdminSettingsForCV(this.$route.query.id).then(
			response => {
				if (response.status == 200) {
					this.validated = response.data.validated
					this.adminComments = response.data.admin_comments
				}
			},
			error => {
				this.dataLoading = false;
				console.error((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);

		CompanyDataService.getLogo(this.$store.state.auth.user.id_company).then(
			response => {
				if (response.status == 200) {
					this.company_img = response.data.logo
				}
			},
			error => {
				this.dataLoading = false;
				console.error((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	},
	methods: {
		imageLoadError(event, item) {
			if (this.currentUser.id != null && this.currentUser.id != undefined && this.currentUser.id == this.data.id_user) {
				if (this.currentUser.avatar != undefined && this.currentUser.avatar != null)
					event.target.src = this.currentUser.avatar//this.$awsBucketRoute + this.currentUser.avatar;
				else
					item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';
			} else if (this.data.id_user != undefined) {
				UserDataService.findOne(this.data.id_user).then(
					response => {
						if (response.data.avatar != undefined && response.data.avatar != null)
							event.target.src = this.$awsBucketRoute + response.data.avatar;
						else
							item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';
					},
					error => {
						item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				)
			} else {
				event.target.src = "http://" + this.currentUrl + '/images/icons/user.png';
			}
		},
		containsAny(str, substrings) {
			for (var i = 0; i != substrings.length; i++) {
				var substring = substrings[i];
				if (str.indexOf(substring) != - 1) {
					return substring;
				}
			}
			return null;
		},
		redirectExternalLink(link) {
			if (link.indexOf('https://') <= -1 || link.indexOf('http://') <= -1) {
				link = 'https://' + link
			}
			window.open(
				link,
				'_blank'
			);
		},
		saveChanges() {
			let cv = { id: this.$route.query.id, validated: this.validated, admin_comments: this.adminComments }
			ProfileAnalysisDataService.updateCVRecord(cv).then(
				response => {
					console.log('')
				},
				error => {
					this.dataLoading = false;
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		copyToClipboard(str) {
			navigator.clipboard.writeText(str).then(
				() => {
					this.showAlertSuccess()
				},
				() => {
					this.showAlertError()
				}
			);
		},
		countDownChangedSuccess(dismissCountDownSuccess) {
			this.dismissCountDownSuccess = dismissCountDownSuccess
		},
		countDownChangedError(dismissCountDownError) {
			this.dismissCountDownError = dismissCountDownError
		},
		showAlertSuccess() {
			this.dismissCountDownSuccess = this.dismissSecsSuccess
		},
		showAlertError() {
			this.dismissCountDownError = this.dismissSecsError
		},
		getContrastColor(hexColor) {
			hexColor = hexColor.replace('#', '');

			let r = parseInt(hexColor.substring(0, 2), 16);
			let g = parseInt(hexColor.substring(2, 4), 16);
			let b = parseInt(hexColor.substring(4, 6), 16);

			let brightness = (r * 299 + g * 587 + b * 114) / 1000;

			return brightness > 128 ? 'black' : 'white';
		}
	}
};
</script>

<style type="text/css" scoped>
.social ul li {
	border-radius: 50%;
	float: left;
	position: relative;
	width: 60px;
	height: 60px;
	text-align: center;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 480px) {
	.social ul li {
		border-width: 4px;
		width: 45px;
		height: 45px;
	}
}

.social ul li:not(:last-child) {
	margin-right: 10px;
}

.social ul li:before {
	content: '';
	border-radius: 50%;
	-webkit-shadow: inset 0 0px 3px rgba(255, 255, 255, 0.9), 0px 0px 0px 2px rgba(255, 255, 255, 0.9);
	-ms-box-shadow: inset 0 0px 3px rgba(255, 255, 255, 0.9), 0px 0px 0px 2px rgba(255, 255, 255, 0.9);
	box-shadow: inset 0 0px 3px rgba(255, 255, 255, 0.9), 0px 0px 0px 2px rgba(255, 255, 255, 0.9);
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 60px;
	height: 60px;
	opacity: 0.35;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 480px) {
	.social ul li:before {
		width: 43px;
		height: 43px;
	}
}

.social ul li:hover {
	border-color: #51c8f3;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.social ul li:hover i {
	color: #d66557;
	-webkit-stroke-width: 5.3px;
	-webkit-stroke-color: #51c8f3;
	-webkit-fill-color: #51c8f3;
	text-shadow: 1px 0px 20px #51c8f3;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.social ul li a {
	border-radius: 50%;
	display: block;
	position: absolute;
	top: -5px;
	left: -5px;
	width: 60px;
	height: 60px;
	line-height: 60px;
}

@media only screen and (max-width: 480px) {
	.social ul li a {
		background-size: cover;
		top: -5px;
		left: -5px;
		width: 45px;
		height: 45px;
		line-height: 45px;
	}
}

.social ul li a:before {
	content: '';
	background-image: url('https://img.picload.org/image/dcocwcga/border-social-hover.png');
	border-radius: 50%;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 46px;
	height: 45px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
	opacity: 0;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 480px) {
	.social ul li a:before {
		background-size: cover;
		width: 30px;
		height: 30px;
	}
}

.social ul li a:after {
	content: '';
	background-image: url('https://img.picload.org/image/dcocwcgd/border-social.png');
	border-radius: 50%;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 46px;
	height: 45px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 480px) {
	.social ul li a:after {
		background-size: cover;
		width: 30px;
		height: 30px;
	}
}

.social ul li a:hover:before {
	opacity: 1;
	-webkit-transform: translate(-50%, -50%) rotate(360deg);
	-ms-transform: translate(-50%, -50%) rotate(360deg);
	transform: translate(-50%, -50%) rotate(360deg);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.social ul li a:hover:after {
	opacity: 0;
	-webkit-transform: translate(-50%, -50%) rotate(360deg);
	-ms-transform: translate(-50%, -50%) rotate(360deg);
	transform: translate(-50%, -50%) rotate(360deg);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.social ul li a img {
	color: #ffffff;
	font-size: 23px;
	font-weight: 900;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-stroke-width: 5.3px;
	-webkit-stroke-color: #ffffff;
	-webkit-fill-color: #ffffff;
	text-shadow: 1px 0px 20px #ffffff;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 480px) {
	.social ul li a img {
		font-size: 16px;
	}
}

.k2t-footer.dark-style .social li a,
.dark-style .social li a {
	color: #fff;
}

.k2t-footer.dark-style .social li a:hover,
.dark-style .social li a:hover {
	color: #fff;
}

.basr-social-share.social li:hover a {
	color: #898989;
}

.basr-social-share.social li a {
	border-width: 0;
	color: #cccccc;
}

.basr-social-share.social li a:hover {
	color: #898989;
}

.basr-social-share.social li a span {
	display: none;
}

.inline-link-icons {
	display: inline-block;
}

.inline-tag-icons {
	width: 100%;
	margin-left: 2%;
}

.progress-bar {
	background-color: #ffffff !important;
}
</style>
